<template>
  <div class="right-menu shipping-right">

    <subHeader pageName="ROBOTS"/>

    <tutorialsAction moduleName="ROBOTS"/>

    <div class="db-blue-section pt-0">
      <div class="db-container">
        <div class="mianBlurBox pt-0">
          <div class="seoDashboardTabsBlock">
            <div class="tabsDataBox">
              <div class="formContentBox mb-0">
                
                <!-- robot.txt block starts here -->
                <div class="masterToolsRow d-block" v-if="!pending">
                  <label for="robotTxt">Robot.txt</label>
                  <textarea 
                    name="robotTxt" 
                    placeholder="Write a robot.txt logic" 
                    v-model="robot" 
                    id="robotTxt"
                    cols="30" 
                    rows="10" 
                    :disabled="tourIsActive"
                  ></textarea>
                </div>
                <!-- robot.txt block ends here -->

                <!-- loader block starts here -->
                <div class="masterToolsRow" style="justify-content: center" v-else>
                  <vue-skeleton-loader
                    type="rect"
                    :width="1450"
                    :height="240"
                    animation="fade"
                  />
                </div>
                <!-- loader block ends here -->

                <!-- save robot.txt block starts here -->
                <div class="dataSaveBtn" style="text-align: right">
                  <button v-if="!save_btn_loading" @click.prevent="saveChanges" id="save-btn" :disabled="tourIsActive">
                    Save robots.txt
                  </button>
                  <loaderBtn v-else/>
                </div>
                <!-- save robot.txt block ends here -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--vue tour-->
    <v-tour name="seoRobotsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name: 'robots',
  metaInfo() {
    return {
      title: 'SEO | Robots | YeetCommerce',
    };
  },
  data(){
    return {
      save_btn_loading: false,
      robot: null,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        //robots steps
        {
          target: '#robotTxt',
          content: `<strong>Robots.txt Content</strong><br>You can input content related to your robots.txt file in this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#save-btn',
          content: `<strong>Save robots.txt Content</strong><br>By clicking this button, the entered content will be saved.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components:{
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      pending: 'robot/isPending',
      robot_txt: 'robot/robots',
      error: 'robot/error',
      message: 'robot/message',
      logoutIsPending:'logoutIsPending'
    }),
  },
  watch:{
    robot_txt:{
      handler: function(value){

        this.robot = value;
        
      },deep: true
    }
  },
  methods:{
    async saveChanges(){

      this.save_btn_loading = true;
      try{
        let res = await this.$axios.post('/updateRobotTxt', {robot: this.robot});
        if(res.data.status_code == "1035"){
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.commit('robot/update_robot_txt', this.robot);

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("doesn't support")){

            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.save_btn_loading = false;

      }
      
    },
    //tour methods
    startSeoRobotTour(){

      this.$tours.seoRobotsTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){
    },
    handlePreviousStep(currentStep){
    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },


    //fetch module data if needed
    fetchModuleData(){

      if(this.robot_txt == '' || this.robot_txt == null){

        try{

          this.$store.dispatch('robot/fetchRobotRequest');

        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }

      }else{

        this.robot = this.robot_txt;

      }

    },

  },
  mounted(){

    //fetching module data
    this.fetchModuleData();

  },
  beforeMount(){


  },
   
}
</script>